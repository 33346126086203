import React,{useEffect, useState} from "react";
import { graphql } from 'gatsby'

import SEO from "../components/seo"
import HeaderOne from "../components/header/header"
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import PageModules from "../components/home"

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../pages/index.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "../scss/grunticon.scss";
import  "../components/MgServices/MgServices.scss";

const HomeTemplate = (props) => {  

    const Modules = props.data.strapiArticles.Modules
    const Page = props.data.strapiArticles
    const AllNewsCategory = props.data.allStrapiNewsCategories.edges

      return(
        <div className="home-page-template">
          <SEO location={props.location} title={Page ? Page.Meta_Title : ''} description={Page ? Page.Meta_Description : ''} />

          {Page.Layout === 'Homepage' || Page.Layout === "Individual_Service_Landing_Page" || Page.Layout === "Local_Love_Landing_Page" ? <HeaderOne /> : <HeaderTwo page={Page.Alias}/> } 

            <div class={`${Page.Layout === 'Default' || Page.Layout === 'Static_Page' || Page.Layout === 'Service_Landing_Page' || Page.Layout === 'Career_Landing_Page'|| Page.Layout === 'Valuation_Page'  ? 'content' : '' } ${Page.Custom_CSS_Class}`}>
              <div className={`${Page.Layout === 'Service_Landing_Page' ? 'mg-services' : '' } ${Page.Layout === 'Individual_Service_Landing_Page' ? 'static-page' : '' } ${Page.Layout === 'Default' ? 'mg-services' : '' } ${Page.Layout === 'Form_Page' ? 'content book-a-valuation' : '' }`}>
                
                <PageModules Modules={Modules} Page={Page} NewsCategory={AllNewsCategory}/>

              </div>
            </div>

          {Page.Layout !== 'Market_Your_Property' &&
          <Footer Select_Popular_Search={Page.Select_Popular_Search} popularSearch="static" showYunoWidget={Page?.Show_Yuno_Widget} />
          }

        </div>
      )
}

export default HomeTemplate


export const pageQuery = graphql`query HomeQuery($id: String!) {
  strapiArticles(strapiId: {eq: $id}) {
    Custom_CSS_Class
    Meta_Description
    Meta_Title
    Pagename
    Alias
    strapiId
    Layout
    all_menus {
      id
    }
    Modules {
      CTA_1_Label
      CTA_2_Label
      Content
      Static_Page
      Banner_Image {
        childImageSharp {
          gatsbyImageData(
            formats: WEBP
            width: 1800
            quality: 80
            layout: FIXED
            transformOptions: {cropFocus: CENTER, fit: COVER}
          )
        }
      }
      Add_Slide {
        Title
        Content
        Image {
          childImageSharp {
            gatsbyImageData(
              formats: WEBP
              width: 1800
              quality: 80
              layout: FIXED
              transformOptions: {cropFocus: CENTER, fit: COVER}
            )
          }
        }
        CTA_1_Label
        CTA_1_URL {
          id
        }
        Mobile_Image {
          childImageSharp {
            gatsbyImageData(formats: WEBP, width: 576, quality: 90, layout: FIXED)
          }
        }
      }
      CTA_1_URL {
        id
      }
      CTA_2_URL {
        id
      }
      CTA_1_Email
      CTA_2_Email
      Header_Title
      Intro_Content
      Intro_Title
      Choose_People {
        Name
        Tile_Image {
          childImageSharp {
            gatsbyImageData(
              formats: WEBP
              width: 80
              height: 100
              quality: 100
              layout: FIXED
              transformOptions: {cropFocus: CENTER, fit: COVER}
            )
          }
        }
        Signature_Image {
          publicURL
        }
        Designation
      }
      Show_Review
      Add_Stats {
        Content
        Count
        id
      }
      Choose_Module
      Add_Services {
        Content
        Label
        Background_Image {
          childImageSharp {
            gatsbyImageData(
              formats: WEBP
              width: 500
              quality: 80
              layout: FIXED
              transformOptions: {cropFocus: CENTER, fit: COVER}
            )
          }
        }
        Select_Link {
          id
        }
        Choose_Link {
          id
        }
      }
      Service_Links_Heading
      Choose_Style
      Place
      Search_Type
      Department
      Choose_Menu_to_Show {
        Sub_Menus {
          id
          Parent_Label
          Parent_URL {
            id
          }
          Add_Sub_Menus {
            Label
            URL {
              id
            }
          }
        }
      }
      Overlay_Content
      Background_Image {
        childImageSharp {
          gatsbyImageData(
            formats: WEBP
            width: 1800
            quality: 80
            layout: FIXED
            transformOptions: {cropFocus: CENTER, fit: COVER}
          )
        }
      }
      Embed_Video_URL
      Service_CTA_Label
      Service_Content
      Service_CTA_Link {
        id
      }
      Service_CTA_Label_Two
      Service_CTA_Link_Two {
        id
      }
      Select_Collection
           Slide {
        Title
        Content
        CTA_1_Label
        CTA_2_Label
        CTA_1_Link {
          id
        }
        CTA_2_Link {
          id
        }
        Background_Image {
          childImageSharp {
            gatsbyImageData(
              formats: WEBP
              width: 1800
              quality: 80
              layout: FIXED
              transformOptions: {cropFocus: CENTER, fit: COVER}
            )
          }
        }
      }
      Reasons {
        Content
        Title
        Link {
          id
        }
        Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
          }
        }
      }
      Choose_Category_to_Show {
        Name
        id
        URL
      }
      Add_New_Block {
        CTA_Label
        Content
        Image {
          publicURL
        }
        CTA_Link {
          id
          Alias
        }
      }
      Add_Video {
        Content
        Embed_Video_URL
        Background_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400, layout: CONSTRAINED)
          }
        }
      }
      Title
      Link {
        Label
        URL {
          id
        }
      }
      Right_Side_Block_CTA_Label_Two
      Right_Side_Block_CTA_Link_Two_Email_to
      Right_Side_Block_Title_Two
      Right_Side_Block_CTA_Link_Email_to
      Right_Side_Block_Title
      Right_Side_Block_CTA_Label
      Right_Side_Block_CTA_Link {
        id
      }
      Right_Side_Block_CTA_Link_Two {
        id
      }
      Right_Side_Block_Title_Three
      Right_Side_Block_CTA_Label_Three
      Right_Side_Block_CTA_Link_Three {
        id
      }
      Right_Side_Block_Make_Recommendation
      Right_Side_Block_CTA_Make_Recommendation
      Right_Side_Block_Link_Make_Recommendation {
        id
      }
      Select_Form
      Form_Heading
      Form_Background_Image {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      Landing_Page_Content
      Landing_Background_Image {
        publicURL
      }
      Add_Valuation_Block {
        CTA_Label
        CTA_Link
        Content
        Title
      }
      Inner_Steps_Background_Image {
        publicURL
      }
      Select_News {
        Title
        URL
        Date
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 500, layout: CONSTRAINED)
          }
        }
        Content {
          Embed_Video_URL
        }
        Category
      }
      Style
      Local_Love_Content
      CTA_Label
      CTA_URL {
        id
        URL
      }
      Image {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 500, layout: CONSTRAINED)
        }
      }
      Add_Image {
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            width: 1800
            quality: 90
            layout: FIXED
            transformOptions: {cropFocus: CENTER, fit: COVER}
          )
        }
      }
      Add_Mobile_Image {
        childImageSharp {
            gatsbyImageData(formats: WEBP, width: 768, quality: 90, layout: FIXED)
        }
      }
      Embed_URL
      Popup_CTA_Text
      Popup_Content
      Sticky_Content
      Sticky_CTA_1_Label
      Sticky_CTA_2_Label
      Sticky_CTA_1_Link {
        id
      }
      Sticky_CTA_2_Link {
        id
      }
      Alignment
    }
    Speak_with {
      Name
      Phone
      Tile_Image {
        publicURL
      }
    }
    Show_How_Can_We_Help
    Show_Yuno_Widget
    Select_Popular_Search
  }
  allStrapiNewsCategories(filter: {Publish: {eq: true}}) {
    edges {
      node {
        strapiId
        URL
        Name
      }
    }
  }
}
`
