import React,{useEffect, useState} from "react";
import ReactMarkdown from "react-markdown"
import {Col, Row, Container } from 'react-bootstrap';
import Loadable from "@loadable/component"
import ModalTiny from "react-bootstrap/Modal"
import logoRange from "../images/logo-range-homes.png";
import { getSrc } from "gatsby-plugin-image"
import Banner from "../components/Home/Banner/banner"
//import Managing from "../components/Home/managing/managing"
// import Started from "../components/Home/started/started"
// import Services from "../components/Home/services/services"
// import CaseStudies from "../components/Home/CaseStudies/CaseStudies"
// import FullVideo from "../components/Home/CaseStudies/full-banner-video"
// import Reviews from "../components/Home/Reviews/Reviews"
const Managing = Loadable(() => import("../components/Home/managing/managing"))
const Started = Loadable(() => import("../components/Home/started/started"))
const Services = Loadable(() => import("../components/Home/services/services"))
const CaseStudies = Loadable(() => import("../components/Home/CaseStudies/CaseStudies"))
const FullVideo = Loadable(() => import("../components/Home/CaseStudies/full-banner-video"))
const Reviews = Loadable(() => import("../components/Home/Reviews/Reviews"))
const TrustPilotWidget = Loadable(() => import("./TrustPilot/TrustPilotWidget"))
const BannerImg = Loadable(() => import("../components/Static/BannerImg/BannerImg"))
const CanHelp = Loadable(() => import("../components/Home/CanHelp/CanHelp"))
const LocalLove = Loadable(() => import("../components/Home/LocalLove"))
const OfficeNumbers = Loadable(() => import("../components/Static/OfficeNumbers/OfficeNumbers"))
const FeaturedProperties = Loadable(() => import("../components/PropertyDetails/Similar/featured-properties"))
const ContentBlocks = Loadable(() => import("../components/Static/content-blocks"))
const ValuationBlock = Loadable(() => import("./valuation/valuationBlock"))
const Fixflow = Loadable(() => import("../components/fixflow"))

const Home = (props) => {

    const [modalRangeOpen,setRangeOpen] = useState(false);
    const closeRangeModal = () => {
      setRangeOpen(false);
    }
    
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
      })

      var urlParams = new URLSearchParams(window.location.search);
      var urlPar = urlParams.get('ref');
      if (urlPar == 'rangehomes') {
        setRangeOpen(true);
      }
     
  }, [])
  
    return (
    <>

        {props.Page.Layout !== "Office_Landing_Page" &&
        <>
        {props.Modules.map((Modules, i) => {
          return (
            <div key={i}>
            	{Modules.Add_Slide !== null &&
            		<>
                    <Banner data={Modules.Add_Slide} page={props.Page.Pagename} />
            		</>
            	}

                {Modules.Add_New_Block !== null &&
                    <ContentBlocks data={Modules.Add_New_Block}/>
                }

                {Modules.Add_Stats !== null &&
                    <OfficeNumbers data={Modules.Add_Stats} class="landing-page"/>
                }

                {Modules.Add_Image !== null && renderComponent &&
                    <BannerImg mobileImage= {getSrc(Modules?.Add_Mobile_Image)} desktopImage={getSrc(Modules.Add_Image)} />
                }

                {Modules.Choose_Module === 'MG_Get_Started' &&
                    <Started page={props.Page.Pagename}/>
                }

                {Modules.Choose_Module === 'Reviews' && renderComponent &&
                    <>
                    <Reviews />
                    </>
                }
                {Modules.Choose_Module === 'Trustpilot_Reviews' && renderComponent &&
                    <>
                    <TrustPilotWidget />
                    </>
                }

                {Modules.Service_Content !== null &&
                    <>
                    <Services Service_Content={Modules.Service_Content} Service_CTA_Label={Modules.Service_CTA_Label} Service_CTA_Link={Modules.Service_CTA_Link} Service_CTA_Label_Two={Modules.Service_CTA_Label_Two} Service_CTA_Link_Two={Modules.Service_CTA_Link_Two} Services={Modules.Add_Services} page={props.Page.Pagename}/>
                    </>
                }

                {Modules.Select_News !== null && renderComponent &&
                    <CaseStudies news={Modules.Select_News} title={Modules.Title} content={Modules.Content} NewsCategory={props.NewsCategory}/>
                }

                {Modules.Background_Image !== null && Modules.Style !== 'Header_Banner' && renderComponent &&
                    <>
                        <FullVideo image={Modules.Background_Image} style={ Modules?.Style} content={Modules.Overlay_Content ? Modules.Overlay_Content : 'Martyn Gerrard'} url={Modules.Embed_Video_URL} />
                    </>
                }

            	{Modules.Intro_Title !== null &&
            		<>
                        <Managing Title={Modules.Intro_Title} Content={Modules.Intro_Content} People={Modules.Choose_People}/>
            		</>
            	}

                {Modules.Local_Love_Content && renderComponent &&
                    <LocalLove content={Modules.Local_Love_Content} image={Modules.Image} label={Modules.CTA_Label} link={Modules.CTA_URL}/>
                }


                {Modules.Place !== null && renderComponent &&
                    <FeaturedProperties place={Modules.Place} type={Modules.Search_Type} tag="landing"/>
                }

                {Modules.Add_Valuation_Block !== null &&
                    <ValuationBlock title_content={Modules?.Landing_Page_Content} valuation_blocks={Modules.Add_Valuation_Block} />
                }

            </div>
          )
        })}
        </>
        }

        {props.Page.Layout === "Homepage" &&
            <Fixflow Modules={props.Modules} id={props.Page.all_menus[0].id} Layout={"Homepage"} />
        }
        
        {props.Page.Show_How_Can_We_Help !== false &&
            <>
            <CanHelp />
            </>
        }
        <ModalTiny backdrop="static" show={modalRangeOpen} onHide={closeRangeModal} className="book-a-viewing-modal range-redirect-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
          </ModalTiny.Header>
          <ModalTiny.Body className="range-popup-body">
            <div className="logo-section">
              <img loading="lazy" className="logo-range" src={logoRange} alt="Range Homes logo"/>
            </div>
            <div><a href="/" className="btn">click here to see why</a></div>
          </ModalTiny.Body>
        </ModalTiny>
    </>
    )
}

export default Home
